import React from "react";
import { Link } from "gatsby";

const BusinessServiceSidebar = ({ activeService }) => {
  return (
    <div className="sticky top-32">
      <div className="ml-auto max-w-[320px] rounded-tl-6xl rounded-br-6xl border border-primary-500 px-9 py-10">
        <div className="heading-five mb-6">Our Business Services</div>
        <div className="mb-6 h-px w-11 bg-primary-500"></div>
        <div className="space-y-5">
          <Link
            to="/business-tax-preparation-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              className="min-w-[23px]"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 16.5532h3M4 3.07376V19.6639c0 .55.21071 1.0774.58579 1.4663.37507.389.88378.6074 1.41421.6074h12c.5304 0 1.0391-.2184 1.4142-.6074.3751-.3889.5858-.9163.5858-1.4663V7.57591c0-.27627-.0533-.54975-.1567-.80437-.1034-.25463-.2548-.48526-.4453-.67838l-4.44-4.50214c-.3736-.37881-.8754-.59095-1.398-.59102H6c-.53043 0-1.03914.21849-1.41421.60739C4.21071 1.9963 4 2.52377 4 3.07376v0zm5 9.33194h6-6z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`stroke-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 0 && "text-primary-500"
                }`}
              />
              <path
                d="M14 .534424V4.68195c0 .55.2107 1.07747.5858 1.46637.3751.38891.8838.6074 1.4142.6074h4"
                strokeWidth="2"
                strokeLinejoin="round"
                className={`stroke-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 0 && "text-primary-500"
                }`}
              />
            </svg>
            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 0 && "text-primary-500"
              }`}
            >
              Business Tax Preparation
            </div>
          </Link>

          <Link
            to="/business-tax-planning-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              className="min-w-[23px]"
              width="23"
              height="27"
              viewBox="0 0 23 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8824 7.34138V5.91019c0-.13119-.1101-.23853-.2447-.23853H4.89344c-.13457 0-.24468.10734-.24468.23853v1.43119c0 .1312.11011.23854.24468.23854H16.6377c.1346 0 .2447-.10734.2447-.23854zM4.89344 9.96524c-.13457 0-.24468.10736-.24468.23856v1.4312c0 .1312.11011.2385.24468.2385h5.62746c.1346 0 .2447-.1073.2447-.2385v-1.4312c0-.1312-.1101-.23856-.2447-.23856H4.89344zM9.29753 23.4423H2.20205V2.45148H19.3291V12.7084c0 .1311.1101.2385.2446.2385h1.7127c.1346 0 .2447-.1074.2447-.2385V1.25882c0-.527756-.4373-.954132-.9787-.954132H.978687C.437351.304688 0 .731064 0 1.25882V24.635c0 .5277.437351.9541.978687.9541H9.29753c.13457 0 .24467-.1073.24467-.2385v-1.6698c0-.1312-.1101-.2385-.24467-.2385zm13.63127 1.5356l-2.8535-2.7819c.682-.8796 1.0888-1.9769 1.0888-3.1665 0-2.8982-2.41-5.2477-5.3828-5.2477-2.9727 0-5.3827 2.3495-5.3827 5.2477 0 2.8981 2.41 5.2477 5.3827 5.2477 1.0949 0 2.1103-.3191 2.9606-.8647l2.8963 2.8236c.0489.0477.1101.0686.1712.0686.0612 0 .1254-.0239.1713-.0686l.9481-.9243c.0226-.0219.0405-.0479.0527-.0766.0122-.0286.0185-.0593.0185-.0904 0-.031-.0063-.0617-.0185-.0904a.233891.233891 0 00-.0527-.0765zm-7.1475-2.609c-1.8931 0-3.4254-1.4938-3.4254-3.3394 0-1.8457 1.5323-3.3395 3.4254-3.3395 1.8932 0 3.4254 1.4938 3.4254 3.3395 0 1.8456-1.5322 3.3394-3.4254 3.3394z"
                className={`fill-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 1 && "text-primary-500"
                }`}
              />
            </svg>

            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 1 && "text-primary-500"
              }`}
            >
              Business Tax Planning
            </div>
          </Link>

          <Link
            to="/outsourced-accounting-bookkeeping-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              className="min-w-[23px]"
              width="23"
              height="25"
              viewBox="0 0 23 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4444.492432H3.83333C2.29233.492432 0 1.45123 0 4.09243V20.8924c0 2.6412 2.29233 3.6 3.83333 3.6H23v-2.4H3.84867c-.59034-.0144-1.29311-.234-1.29311-1.2 0-.1212.0115-.2292.03066-.3276.14311-.69.74495-.8604 1.26117-.8724H21.7222c.023 0 .0396-.0108.0626-.012H23V2.89243c0-1.3236-1.1462-2.399998-2.5556-2.399998zm0 16.799968H2.55556V4.09243c0-.9672.70277-1.1856 1.27777-1.2h8.94447v8.39997l2.5555-1.2 2.5556 1.2V2.89243h2.5555V17.2924z"
                className={`fill-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 2 && "text-primary-500"
                }`}
              />
            </svg>

            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 2 && "text-primary-500"
              }`}
            >
              Accounting & Bookkeeping
            </div>
          </Link>

          <Link
            to="/business-consulting-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.32598 24.0763c1.3923-.0002 2.74562-.4599 3.85012-1.3079 1.1045-.8479 1.8983-2.0366 2.2585-3.3819.3602-1.3452.2666-2.7717-.2663-4.0583-.533-1.2866-1.4754-2.3613-2.6813-3.0575-1.20577-.6962-2.60758-.975-3.98796-.7932-1.38038.1819-2.66219.8142-3.64666 1.799-.98447.9848-1.61657 2.267-1.79828 3.6477-.181701 1.3807.09714 2.7828.7933 3.9889L1.31725 23.76l2.84624-.5291c.96118.5558 2.05223.8475 3.16249.8454v0z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`stroke-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 3 && "text-primary-500"
                }`}
              />
              <path
                d="M16.2751 19.358c1.1011-.1773 2.1605-.5546 3.1257-1.1135l4.14.7707-.7705-4.141c.6765-1.1738 1.0855-2.4827 1.1978-3.8329.1122-1.35031-.075-2.70873-.5483-3.97826-.4734-1.26953-1.2212-2.41884-2.19-3.36584-.9688-.94699-2.1348-1.66831-3.4145-2.11242a9.198159 9.198159 0 00-3.9889-.45672 9.198688 9.198688 0 00-3.8035 1.28599c-1.15769.70351-2.14579 1.65407-2.89371 2.78377-.74793 1.12969-1.23721 2.41065-1.4329 3.75138"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`stroke-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 3 && "text-primary-500"
                }`}
              />
            </svg>

            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 3 && "text-primary-500"
              }`}
            >
              Business Consulting
            </div>
          </Link>

          <Link
            to="/business-valuation-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              className="min-w-[23px]"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5.869629c-1.1438 0-2.11865.884191-2.48842 2.095241H2.65385v2.09524h1.24377l-.24858.5238L.110577 12.9172 0 13.1792v.2619c0 2.8809 1.99038 5.2381 4.42308 5.2381 2.43269 0 4.42307-2.3572 4.42307-5.2381v-.2619l-.11057-.262-3.53846-7.33329-.24858-.5238h4.06392c.13299.43657.34556.83309.6226 1.16136.27704.32827.61174.58023.98034.73797V20.7744H7.07692v2.0952h8.84618v-2.0952h-3.5385V6.95839c.3681-.15876.7023-.41094.9794-.73887.277-.32792.4902-.72364.6244-1.15941h4.0631l-.2486.5238-3.5385 7.33329-.1106.262v.2619c0 2.8809 1.9904 5.2381 4.4231 5.2381 2.4327 0 4.4231-2.3572 4.4231-5.2381v-.2619l-.1106-.262-3.5384-7.33329-.2486-.5238h1.2438V2.96487h-6.3587C13.6187 1.75382 12.6447.869629 11.5.869629zm0 2.095241c.498 0 .8846.45885.8846 1.04762 0 .58981-.3874 1.04762-.8846 1.04762-.498 0-.8846-.45886-.8846-1.04762 0-.58981.3874-1.04762.8846-1.04762zM4.42308 8.23544l2.0178 4.15796H2.40615l2.01693-4.15796zm14.15382 0l2.0178 4.15796H16.56l2.0169-4.15796zM1.99038 14.4887h4.86539c-.35562 1.2152-1.27827 2.0952-2.43269 2.0952-1.15443 0-2.07708-.88-2.4327-2.0952zm14.15382 0h4.8654c-.3556 1.2152-1.2783 2.0952-2.4327 2.0952s-2.0771-.88-2.4327-2.0952z"
                className={`fill-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 4 && "text-primary-500"
                }`}
              />
            </svg>
            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 4 && "text-primary-500"
              }`}
            >
              Business Valuation
            </div>
          </Link> 

          <Link
            to="/tax-audit-attestation-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              className="min-w-[23px]"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.95366.476562c2.16724.000001 4.24564.860918 5.77804 2.393358 1.5325 1.53244 2.3934 3.61087 2.3934 5.77807 0 2.02401-.7417 3.88461-1.9612 5.31771l.3395.3394h.9931l6.2857 6.2857-1.8857 1.8858-6.2857-6.2858v-.9931l-.3394-.3394c-1.4828 1.2656-3.3683 1.961-5.31774 1.9611-2.1672 0-4.24564-.8609-5.77808-2.3933C1.64314 12.8936.782227 10.8152.782227 8.64799c0-2.1672.860913-4.24563 2.393353-5.77807C4.70802 1.33748 6.78646.476563 8.95366.476562zm0 2.514288c-3.14286 0-5.65715 2.51428-5.65715 5.65714 0 3.14281 2.51429 5.65711 5.65715 5.65711 3.14284 0 5.65714-2.5143 5.65714-5.65711 0-3.14286-2.5143-5.65714-5.65714-5.65714z"
                className={`fill-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 5 && "text-primary-500"
                }`}
              />
            </svg>
            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 5 && "text-primary-500"
              }`}
            >
              Audit & Attestation Services
            </div>
          </Link>

          <Link
            to="/irs-representation-services/"
            className="group flex items-center space-x-3 no-underline"
          >
            <svg
              className="min-w-[23px]"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.30769 0C8.382 0 7.61538.787826 7.61538 1.73913v.86957H5.07692v-.86957h-1.6923v.86957H1.69231C.766615 2.6087 0 3.39652 0 4.34783V18.2609C0 19.2122.766615 20 1.69231 20H20.3077C21.2334 20 22 19.2122 22 18.2609V4.34783c0-.95131-.7666-1.73913-1.6923-1.73913h-1.6923v-.86957h-1.6923v.86957h-2.5385v-.86957C14.3846.787826 13.618 0 12.6923 0H9.30769zm0 1.73913h3.38461v.86957H9.30769v-.86957zm-7.61538 2.6087H20.3077V18.2609h-1.6923V5.21739h-1.6923V18.2609H5.07692V5.21739h-1.6923V18.2609H1.69231V4.34783z"
                className={`fill-current text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                  activeService === 6 && "text-primary-500"
                }`}
              />
            </svg>
            <div
              className={`text-lg text-gray-500 transition-colors duration-300 ease-linear group-hover:text-primary-500 ${
                activeService === 6 && "text-primary-500"
              }`}
            >
              IRS Representation
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BusinessServiceSidebar;
