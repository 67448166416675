import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "gatsby";

import SliderServices from "../../components/Slider/SliderServices";

const BusinessServiceSlider = ({ hideService }) => {
  const data = useStaticQuery(graphql`
    {
      taxPreparation: file(
        relativePath: {
          eq: "2.0 Business Hub/1.0 Business Tax Preparation.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      taxPlanning: file(
        relativePath: { eq: "2.0 Business Hub/1.1 Business Tax Planning.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      bookkeeping: file(
        relativePath: {
          eq: "2.0 Business Hub/1.3 Outsourced Accounting _ Bookkeeping.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      businessConsulting: file(
        relativePath: { eq: "2.0 Business Hub/1.4 Business Consulting .jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      businessValuation: file(
        relativePath: { eq: "2.0 Business Hub/1.5 Business Valuation.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      auditAttestation: file(
        relativePath: {
          eq: "2.0 Business Hub/1.6 Audit & Attestation Services.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      irsRepresentation: file(
        relativePath: { eq: "2.0 Business Hub/1.7 IRS Representation.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
    }
  `);

  const sliderContent = [
    {
      image: data.taxPreparation.childImageSharp.gatsbyImageData,
      title: "Business Tax Preparation",
      text: "Get peace of mind knowing your taxes have been prepared correctly and professionally.",
      href: "/business-tax-preparation-services/",
    },
    {
      image: data.taxPlanning.childImageSharp.gatsbyImageData,
      title: "Business Tax Planning",
      text: "Tax planning is the best way to reduce tax liabilities for any business. We’ll help you identify tax-saving strategies.",
      href: "/business-tax-planning-services/",
    },
    {
      image: data.bookkeeping.childImageSharp.gatsbyImageData,
      title: "Accounting & Bookkeeping",
      text: "Let us handle your day-to-day bookkeeping and accounting tasks, including payroll and financial statements.",
      href: "/outsourced-accounting-bookkeeping-services/",
    },
    {
      image: data.businessConsulting.childImageSharp.gatsbyImageData,
      title: "Business Consulting",
      text: "When you need advice, lean on our experience in strategic planning, feasibility studies, information systems evaluation, outsourcing, and more.",
      href: "/business-consulting-services/",
    },
    // {
    //   image: data.businessValuation.childImageSharp.gatsbyImageData,
    //   title: "Business Valuation",
    //   text: "Find out what your business is worth with a valuation that determines its economic value.",
    //   href: "/business-valuation-services/",
    // },
    {
      image: data.auditAttestation.childImageSharp.gatsbyImageData,
      title: "Audit & Attestation Services",
      text: "We’ll audit your books to see if your processes need to be improved. We can also look for evidence of criminal behavior.",
      href: "/tax-audit-attestation-services/",
    },
    {
      image: data.irsRepresentation.childImageSharp.gatsbyImageData,
      title: "IRS Representation",
      text: "Are you worried about the IRS? Don’t panic. As your representative, we can handle all communications with them.",
      href: "/irs-representation-services/",
    },
  ];

  return (
    <section className="overflow-x-hidden bg-primary-100 pt-18 pb-24">
      <div className="container relative">
        <div className="absolute left-0 top-0 z-10 hidden h-full w-full -translate-x-full transform bg-primary-100 md:block"></div>
        <header className="mb-10 md:mb-14">
          <h2 className="text-gray-700">
            We Also Offer These Business Services
          </h2>
        </header>

        <SliderServices>
          {sliderContent.map(
            (item, i) =>
              i !== hideService && (
                <div key={i}>
                  <Link to={item.href} className="group no-underline">
                    <div className="-mx-4 mb-3.5 overflow-hidden md:mx-0">
                      <GatsbyImage
                        image={item.image}
                        className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="heading-five mb-3 text-gray-700">
                      {item.title}
                    </div>
                    <p className="mb-0 font-normal text-gray-500">
                      {item.text}
                    </p>
                  </Link>
                </div>
              )
          )}
        </SliderServices>
      </div>
    </section>
  );
};

export default BusinessServiceSlider;
