import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Business Tax Preparation Services | DC, MD & VA | DeBlanc"
        description="Get professional business tax preparation services from a top Washington, DC, area CPA firm. Call DeBlanc + Murphy today to get started."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Business Tax Preparation Services</h1>
            <p className="mb-0 md:text-xl">
              Leave the tax preparation to us so you can do what you do best—run
              your business. We serve the greater Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Our Goal is to Save You Money</h2>
                <p>
                  Our business tax preparers have extensive experience preparing
                  returns for small and medium-sized businesses, corporations,
                  partnerships, non-profits, and more. We’ve earned a reputation
                  for being creative and aggressive in finding ways to reduce
                  taxes.
                </p>
                <p>
                  We provide strong tax strategies for our clients by
                  consistently monitoring tax law changes, regulations, and
                  court rulings throughout the year, so we’re always on top of
                  the most current tax developments. When it’s time to prepare
                  your taxes, you’re already ahead of the game.
                </p>
              </div>

              <div className="mb-16">
                <h3>Other Benefit</h3>
                <p>
                  Professional business tax preparation services can save you
                  time and money, so you can focus on generating more profits or
                  exploring new business opportunities.
                </p>
                <p>
                  You’ll also be in good standing if you ever get audited by the
                  IRS. Our professional tax preparers know what the IRS looks
                  for and will carefully review your tax return to help you
                  avoid an IRS audit.
                </p>
              </div>

              <div>
                <h3>Businesses We Work With</h3>
                <p>
                  We’re a full-service CPA firm that works with clients big and
                  small—we partner with large corporations, offer small business
                  tax preparation services, and help every type of business in
                  between, including:
                </p>
                <ul className="styled-list-plus mb-6">
                  <li>Sole Proprietorships</li>
                  <li>Limited Liability Corporations</li>
                  <li>Corporations</li>
                  <li>Partnerships</li>
                  <li>Non-Profits</li>
                  <li>Start-ups</li>
                </ul>

                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={0} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={0} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "2.1 Business Tax Preparation/1.0Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
